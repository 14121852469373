import {
    Navbar,
    NavbarMenu,
    NavbarMenuItem,
    NavbarMenuToggle,
    NavbarBrand,
    NavbarContent,
    NavbarItem,
    Link,
    Button,
    Card,
    CardBody,
    Accordion,
    AccordionItem
} from "@nextui-org/react";
import { MapPin, Mail, Phone, FileText, CheckSquare, Home, CheckCircle, Briefcase } from "lucide-react";
import { useState } from "react";
export default function HomePage() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [showEmail, setShowEmail] = useState(false);
    // Split email into parts to avoid basic scraping
    const emailName = "pete";
    const emailDomain = "hartsc.com";

    const scrollToSection = (event, sectionId) => {
        event.preventDefault();
        const element = document.getElementById(sectionId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
<div className="min-h-screen bg-[#f3fbfb]">
    <Navbar onMenuOpenChange={setIsMenuOpen}>
        <NavbarContent>
            <NavbarMenuToggle
                aria-label={isMenuOpen ? "Close menu" : "Open menu"}
                className="sm:hidden"
            />
            <NavbarBrand>
                <img
                    src="/white-pete.jpg"
                    alt="Logo"
                    className="w-24 h-auto mr-4"
                />
            </NavbarBrand>
        </NavbarContent>

        <NavbarContent className="hidden sm:flex gap-4" justify="center">
                    <NavbarItem>
                        <Link
                            href="#services"
                            color="foreground"
                            onClick={(e) => scrollToSection(e, 'services')}
                        >
                            Services
                        </Link>
                    </NavbarItem>
                    <NavbarItem>
                        <Link
                            href="#process"
                            color="foreground"
                            onClick={(e) => scrollToSection(e, 'process')}
                        >
                            Our Process
                        </Link>
                    </NavbarItem>
                    <NavbarItem>
                        <Link
                            href="#checklist"
                            color="foreground"
                            onClick={(e) => scrollToSection(e, 'checklist')}
                        >
                            Checklist
                        </Link>
                    </NavbarItem>
                    <NavbarItem>
                        <Link
                            href="#contact"
                            color="foreground"
                            onClick={(e) => scrollToSection(e, 'contact')}
                        >
                            Contact
                        </Link>
                    </NavbarItem>
                </NavbarContent>

                <NavbarContent justify="end">
            <NavbarItem>
                <Button 
                    as="a" 
                    color="primary" 
                    variant="flat" 
                    target="_blank" 
                    href="https://calendly.com/pete-hartsc/tax-meeting"
                    className="text-sm md:text-base"
                >
                    Book Appointment
                </Button>
            </NavbarItem>
        </NavbarContent>

                <NavbarMenu>
                    <NavbarMenuItem>
                        <Link href="#services" color="foreground">Our Service</Link>
                    </NavbarMenuItem>
                    <NavbarMenuItem>
                        <Link href="#process" color="foreground">Our Process</Link>
                    </NavbarMenuItem>
                    <NavbarMenuItem>
                        <Link href="#checklist" color="foreground">Checklist</Link>
                    </NavbarMenuItem>
                    <NavbarMenuItem>
                        <Link href="#contact" color="foreground">Contact</Link>
                    </NavbarMenuItem>
                </NavbarMenu>
            </Navbar>

            <div className="max-w-6xl mx-auto px-4 md:px-6">
                {/* Hero Section */}
                <div className="text-center py-8 md:py-16">
                    <img
                        src="/pete-blue.jpg"
                        alt="Logo"
                        className="mx-auto max-w-full h-auto"
                        //style={{ display: 'flex', flexDirection: 'column' }}
                    />
                </div>

                {/* Services Section */}
                <div className="py-8 md:py-12" id="services">
            <h2 className="text-2xl md:text-3xl font-bold mb-4 text-center">Complete Tax Preparation Service</h2>
            <p className="text-center text-gray-600 mb-8 md:mb-12 max-w-3xl mx-auto px-4">
                From simple returns to complex portfolios, we provide professional tax preparation for all situations. Our comprehensive service adapts to your specific needs.
            </p>

            <Card className="shadow-lg">
                <CardBody className="p-4 md:p-8">
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-8">
                        <div className="space-y-4 md:space-y-6">
                            <h3 className="text-lg md:text-xl font-semibold mb-3">What We Offer</h3>
                            <div className="space-y-3 md:space-y-4">
                                <div className="flex gap-2 md:gap-3">
                                    <CheckCircle className="w-5 h-5 md:w-6 md:h-6 text-primary flex-shrink-0" />
                                    <p className="text-sm md:text-base">Effortless filing process that lets you get on with life while we handle the details</p>
                                </div>
                                <div className="flex gap-2 md:gap-3">
                                    <CheckCircle className="w-5 h-5 md:w-6 md:h-6 text-primary flex-shrink-0" />
                                    <p className="text-sm md:text-base">Expert knowledge of the latest tax laws and updates that affect your return</p>
                                </div>
                                <div className="flex gap-2 md:gap-3">
                                    <CheckCircle className="w-5 h-5 md:w-6 md:h-6 text-primary flex-shrink-0" />
                                    <p className="text-sm md:text-base">Meticulous attention to detail ensuring accuracy in every filing</p>
                                </div>
                            </div>
                        </div>

                        <div className="space-y-4 md:space-y-6">
                            <h3 className="text-lg md:text-xl font-semibold mb-3">Who We Help</h3>
                            <div className="space-y-3 md:space-y-4">
                                <div className="flex gap-2 md:gap-3">
                                    <CheckCircle className="w-5 h-5 md:w-6 md:h-6 text-primary flex-shrink-0" />
                                    <p className="text-sm md:text-base">Employees with T4 income and basic returns</p>
                                </div>
                                <div className="flex gap-2 md:gap-3">
                                    <CheckCircle className="w-5 h-5 md:w-6 md:h-6 text-primary flex-shrink-0" />
                                    <p className="text-sm md:text-base">Investors managing multiple income streams</p>
                                </div>
                                <div className="flex gap-2 md:gap-3">
                                    <CheckCircle className="w-5 h-5 md:w-6 md:h-6 text-primary flex-shrink-0" />
                                    <p className="text-sm md:text-base">Business owners and self-employed individuals</p>
                                </div>
                                <div className="flex gap-2 md:gap-3">
                                    <CheckCircle className="w-5 h-5 md:w-6 md:h-6 text-primary flex-shrink-0" />
                                    <p className="text-sm md:text-base">People with side income or complex tax situations</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </div>

        <div className="py-8 md:py-12" id="process">
            <h2 className="text-2xl md:text-3xl font-bold mb-6 md:mb-8 text-center">How We Work</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6">
                <Card>
                    <CardBody className="p-4 md:p-6">
                        <h3 className="text-lg md:text-xl font-semibold mb-2">1. Meet with you</h3>
                        <p className="text-sm md:text-base text-gray-600">We will discuss your situation, sources of income, and expenses to get a detailed understanding of your tax return filing requirements.</p>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody className="p-4 md:p-6">
                        <h3 className="text-lg md:text-xl font-semibold mb-2">2. Price Upfront</h3>
                        <p className="text-sm md:text-base text-gray-600">You will know exactly the cost before we start working on your tax return.</p>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody className="p-4 md:p-6">
                        <h3 className="text-lg md:text-xl font-semibold mb-2">3. Collect Tax Information</h3>
                        <p className="text-sm md:text-base text-gray-600">We will collect all tax slips from Canada Revenue Agency directly and present them clearly for your review. No need to spend time scanning, uploading, or hunting for them.</p>
                    </CardBody>
                </Card>
                <Card>
                    <CardBody className="p-4 md:p-6">
                        <h3 className="text-lg md:text-xl font-semibold mb-2">4. Complete & E-File</h3>
                        <p className="text-sm md:text-base text-gray-600">We will ensure you claim every deduction available. We will submit your return electronically, ensuring a safe transmission and providing an EFILE confirmation on completion.</p>
                    </CardBody>
                </Card>
            </div>
        </div>

        <div className="py-8 md:py-12" id="checklist">
            <h2 className="text-2xl md:text-3xl font-bold mb-6 md:mb-8 text-center">Tax Preparation Checklist</h2>
            <Accordion>
                <AccordionItem key="1" aria-label="Income" title={<span className="text-sm md:text-base">Income</span>} startContent={<FileText className="text-primary w-5 h-5 md:w-6 md:h-6" />}>
                    <ul className="list-disc pl-4 md:pl-6 space-y-1 md:space-y-2 text-sm md:text-base">
                        <li>All registered Tax Slips for income may be obtained on-line</li>
                        <li>Income not reported on tax slips</li>
                    </ul>
                </AccordionItem>
                <AccordionItem key="2" aria-label="Receipts for Expenses" title={<span className="text-sm md:text-base">Receipts for Expenses</span>} startContent={<CheckSquare className="text-primary w-5 h-5 md:w-6 md:h-6" />}>
                    <ul className="list-disc pl-4 md:pl-6 space-y-1 md:space-y-2 text-sm md:text-base">
                        <li>RRSP Contributions</li>
                        <li>Tuition receipts</li>
                        <li>Union Dues</li>
                        <li>Exams or Certifications</li>
                        <li>Teacher School Supplies</li>
                        <li>Employment Costs</li>
                        <li>Home Office Expenses</li>
                        <li>Childcare</li>
                        <li>Disability</li>
                        <li>Medical</li>
                        <li>Personal Attendant</li>
                        <li>Charitable Contributions</li>
                        <li>Political Contributions</li>
                        <li>Home Accessibility Expenses</li>
                        <li>Moving Expenses</li>
                        <li>Student Loan Interest Amounts</li>
                    </ul>
                </AccordionItem>
                <AccordionItem key="3" aria-label="Rental Property" title={<span className="text-sm md:text-base">Rental Property</span>} startContent={<Home className="text-primary w-5 h-5 md:w-6 md:h-6" />}>
                    <ul className="list-disc pl-4 md:pl-6 space-y-1 md:space-y-2 text-sm md:text-base">
                        <li>Rental Income</li>
                        <li>Professional fees</li>
                        <li>Insurance Premiums</li>
                        <li>Mortgage Interest</li>
                        <li>Property Tax</li>
                        <li>Repairs & Maintenance</li>
                    </ul>
                </AccordionItem>
                <AccordionItem key="4" aria-label="Self Employed" title={<span className="text-sm md:text-base">Self Employed</span>} startContent={<Briefcase className="text-primary w-5 h-5 md:w-6 md:h-6" />}>
                    <ul className="list-disc pl-4 md:pl-6 space-y-1 md:space-y-2 text-sm md:text-base">
                        <li>Gross Earnings</li>
                        <li>Expenses</li>
                        <li>List of all capital goods</li>
                        <li>Vehicle Expenses</li>
                    </ul>
                </AccordionItem>
            </Accordion>
        </div>

        <footer className="py-6 md:py-8 mt-8 md:mt-12 border-t" id="contact">
    <div className="max-w-6xl mx-auto px-4 md:px-6">
        <Card className="bg-white/60">
            <CardBody className="p-4 md:p-6">
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-8">
                    <div className="space-y-2 md:space-y-3">
                        <div className="flex items-center gap-2">
                            <MapPin className="w-5 h-5" />
                            <a href="https://maps.app.goo.gl/R2nJnGzMgrniMEuLA" 
                               target="_blank" 
                               className="text-sm md:text-base text-gray-600 hover:text-primary transition-colors">
                                380 Cook Street, 103 Rear Business Centre Victoria BC V8V 3X7
                            </a>
                        </div>
                        <div className="flex items-center gap-2">
                            <Phone className="w-5 h-5" />
                            <a href="tel:250-410-2144" 
                               className="text-sm md:text-base text-gray-600 hover:text-primary transition-colors">
                                250-410-2144
                            </a>
                        </div>
                        <div className="flex items-center gap-2">
                            <Mail className="w-5 h-5 text-gray-600" />
                            {showEmail ? (
                                <a href={`mailto:${emailName}@${emailDomain}`}
                                   className="text-sm md:text-base text-gray-600 hover:text-primary transition-colors">
                                    {emailName}@{emailDomain}
                                </a>
                            ) : (
                                <Button
                                    size="sm"
                                    variant="light"
                                    onClick={() => setShowEmail(true)}
                                    className="text-sm md:text-base"
                                >
                                    Show Email
                                </Button>
                            )}
                        </div>
                    </div>

                    <div className="space-y-2 flex flex-col items-center md:items-start">
                        <Button 
                            as="a" 
                            color="primary" 
                            variant="flat" 
                            target="_blank" 
                            href="https://calendly.com/pete-hartsc/tax-meeting"
                            className="w-full md:w-auto text-sm md:text-base"
                        >
                            Book Appointment
                        </Button>
                    </div>

                    <div className="text-sm md:text-base text-gray-600">
                        <h3 className="font-semibold mb-2">Office Hours (By appointment)</h3>
                        <p>9 AM - 6 PM everyday</p>
                        <p>During tax season</p>
                    </div>
                </div>

                <div className="text-center text-gray-500 text-xs md:text-sm mt-6 md:mt-8">
                    © {new Date().getFullYear()} Pete Hartman Tax Services. All rights reserved.
                </div>
            </CardBody>
        </Card>
    </div>
</footer>
            </div>
        </div>
    );
}