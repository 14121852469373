import './App.css';
import { Route, Routes } from "react-router-dom";
import Home from "./routes/Home.js"

function App() {

  return (
    <Routes>
      <Route path="/" Component={Home} />
    </Routes>
  );
}

export default App;
